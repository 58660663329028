
import React, { useState, useEffect } from 'react';
import "../styles/layout/popup.scss";

const Popup = () => {


    const [showPopup, setShowPopup] = useState(false);
    useEffect(() => {
      // Mostrar el pop-up después de 1 segundo
      const timer = setTimeout(() => {
          setShowPopup(true);
      }, 1000);

      // Limpiar el temporizador cuando el componente se desmonte
      return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
      setShowPopup(false);
  };


  return (
    <>
    {showPopup && (
      <section className="popup">
        <article className="popup__white" id='modal'>
          <div className='popup__close' onClick={handleClose}>
            <i className='fa-solid fa-x popup__close-icon'></i>
          </div>
        </article>
      </section>
    )}
  </>
  );
};
export default Popup;
